import { AbilityBuilder, Ability } from '@casl/ability'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
const userRole = userData ? userData.user_type : 'Anon'

export function defineRulesFor(userRole) {
  const { can, cannot, rules } = new AbilityBuilder(Ability)

  switch (userRole) {
    case 'Администратор':
      can('manage', 'all')
      break
    case 'Менеджер':
      can('manage', 'all')
      cannot('manage', 'Users')
      break
    case 'Инвестор':
      can('manage', 'all')
      cannot('read', 'All')
      break
    case 'Брокер':
      can('manage', 'all')
      cannot('read', 'All')
      break
    case 'Клиент':
      cannot('manage', 'all')
      cannot('read', 'all')
      break
    default:
      can('read', 'Auth')
  }
  return new Ability(rules)
}

const ability = defineRulesFor(userRole)

export default ability
