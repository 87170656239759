import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const { jwt } = useJwt(axios, {
  loginEndpoint: '/users/token/',
  refreshEndpoint: '/users/token/refresh/',
  storageTokenKeyName: 'access',
  storageRefreshTokenKeyName: 'refresh',
})
export default jwt
