import Vue from 'vue'

// axios
import axios from 'axios'

const urls = {
  production: 'https://api.finkit.by/',
  stage: 'https://staging-api.finkit.by/',
  development: 'http://127.0.0.1:8000/',
}

const baseUrl = urls[process.env.NODE_ENV]

const axiosIns = axios.create({
  baseURL: baseUrl,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
