import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        resource: 'all',
        action: 'manage',

        pageTitle: 'Дашборд',
        breadcrumb: [
          {
            text: 'Дашборд',
            active: true,
          },
        ],
      },
    },
    {
      path: '/phone-calls',
      name: 'phone-calls-list',
      component: () => import('@/views/phone-calls/PhoneCallsList.vue'),
      meta: {
        resource: 'all',
        action: 'manage',
        pageTitle: 'Звоник',
        breadcrumb: [
          {
            text: 'Звонки',
            active: true,
          },
        ],
      },
    },
    {
      path: '/applications',
      name: 'applications-list',
      component: () => import('@/views/applications/ApplicationsList.vue'),
      meta: {
        resource: 'all',
        action: 'manage',

        pageTitle: 'Заявки',
        breadcrumb: [
          {
            text: 'Заявки',
            active: true,
          },
        ],
      },
    },
    {
      path: '/applications/create',
      name: 'application-create',
      component: () => import('@/views/applications/ApplicationCreate.vue'),
      meta: {
        resource: 'all',
        action: 'manage',
        pageTitle: 'Создать заявку',
        breadcrumb: [
          {
            text: 'Заявки',
            to: { name: 'applications-list' },
          },
          {
            text: ' Создать',
            active: true,
          },
        ],
      },
    },
    {
      path: '/applications/:appId',
      name: 'application-view',
      component: () => import('@/views/applications/ApplicationView.vue'),
      meta: {
        resource: 'all',
        action: 'manage',
        pageTitle: 'Просмотр заявки',
        breadcrumb: [
          {
            text: 'Заявки',
            to: { name: 'applications-list' },
          },
          {
            text: ' Заявка',
            active: true,
          },
        ],
      },
    },
    {
      path: '/application-add-doc/:appId/:id',
      name: 'application-add-doc',
      component: () => import('@/views/clients/components/ClientCreateDoc.vue'),
      meta: {
        pageTitle: 'Добавить документ',

        resource: 'all',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Заявки',
            to: { name: 'applications-list' },
          },
          {
            text: 'Заявка',
            to: { name: 'application-view' },
          },
          {
            text: 'Добавить документ',
            active: true,
          },
        ],
      },
    },
    {
      path: '/deals',
      name: 'deals-list',
      component: () => import('@/views/deals/DealsList.vue'),
      meta: {
        pageTitle: 'Сделки',

        resource: 'Deal',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Сделки',
            active: true,
          },
        ],
      },
    },
    {
      path: '/deals/:id',
      name: 'deal-view',
      component: () => import('@/views/deals/DealView.vue'),
      meta: {
        pageTitle: 'Сделка',

        resource: 'Deal',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Сделки',
            to: { name: 'deals-list' },
          },
          {
            text: 'Сделка',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payments',
      name: 'payments-list',
      component: () => import('@/views/deals/PaymentsList.vue'),
      meta: {
        pageTitle: 'Платежи',
        breadcrumb: [
          {
            text: 'Платежи',
            active: true,
          },
        ],
      },
    },

    {
      path: '/delays',
      name: 'delays-list',
      component: () => import('@/views/deals/DelayList.vue'),
      meta: {
        pageTitle: 'Просрочка',
        breadcrumb: [
          {
            text: 'Просрочка',
            active: true,
          },
        ],
      },
    },

    {
      path: '/investments',
      name: 'investments-list',
      component: () => import('@/views/finances/InvestmentsList.vue'),
      meta: {
        pageTitle: 'Инвестиции',
        breadcrumb: [
          {
            text: 'Инвестиции',
            active: true,
          },
        ],
      },
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('@/views/finances/Statistics.vue'),
      meta: {
        pageTitle: 'Статистика',
        breadcrumb: [
          {
            text: 'Статистика',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients',
      name: 'clients-list',
      component: () => import('@/views/clients/ClientsList.vue'),
      meta: {
        pageTitle: 'Клиенты',
        breadcrumb: [
          {
            text: 'Клиенты',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients/create',
      name: 'client-create',
      component: () => import('@/views/clients/ClientCreate.vue'),
      meta: {
        pageTitle: 'Добавить клиента',
        breadcrumb: [
          {
            text: 'Клиенты',
            to: { name: 'clients-list' },
          },
          {
            text: 'Добавить клиента',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients/:id',
      name: 'client-view',
      component: () => import('@/views/clients/ClientView.vue'),
      meta: {
        pageTitle: 'Клиент',

        resource: 'all',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Клиенты',
            to: { name: 'clients-list' },
          },
          {
            text: 'Клиент',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients-update/:id',
      name: 'client-update',
      component: () => import('@/views/clients/ClientUpdate.vue'),
      meta: {
        pageTitle: 'Изменить данные клиента',

        resource: 'all',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Клиенты',
            to: { name: 'clients-list' },
          },
          {
            text: 'Клиент',
            to: { name: 'client-view' },
          },
          {
            text: 'Изменить данные клиента',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients-add-doc/:id',
      name: 'client-add-doc',
      component: () => import('@/views/clients/components/ClientCreateDoc.vue'),
      meta: {
        pageTitle: 'Добавить документ',

        resource: 'all',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Клиенты',
            to: { name: 'clients-list' },
          },
          {
            text: 'Клиент',
            to: { name: 'client-view' },
          },
          {
            text: 'Добавить документ',
            active: true,
          },
        ],
      },
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: () => import('@/views/helpers/Downloads.vue'),
      meta: {
        pageTitle: 'Выгрузки',

        resource: 'all',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Выгрузки',
            to: { name: 'downloads' },
            active: true,
          },
        ],
      },
    },

    {
      path: '/notifications',
      name: 'notifications-list',
      component: () => import('@/views/notifications/NotificationsList.vue'),
      meta: {
        pageTitle: 'Уведомления',

        resource: 'all',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Уведомления',
            to: { name: 'notifications-list' },
            active: true,
          },
        ],
      },
    },

    {
      path: '/control-list',
      name: 'control-list',
      component: () => import('@/views/deals/ControlList.vue'),
      meta: {
        pageTitle: 'Контрольные даты',

        resource: 'all',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Контрольные даты',
            to: { name: 'control-list' },
            active: true,
          },
        ],
      },
    },

    {
      path: '/users',
      name: 'users-list',
      component: () => import('@/views/users/UsersList.vue'),
      meta: {
        pageTitle: 'Пользователи',

        resource: 'all',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Пользователи',
            to: { name: 'users-list' },
            active: true,
          },
        ],
      },
    },

    {
      path: '/cars',
      name: 'car-list',
      component: () => import('@/views/helpers/CarList.vue'),
      meta: {
        pageTitle: 'Каталог авто',

        resource: 'all',
        action: 'manage',

        breadcrumb: [
          {
            text: 'Каталог авто',
            to: { name: 'car-list' },
            active: true,
          },
        ],
      },
    },

    {
      path: '/brokers',
      name: 'brokers-list',
      component: () => import('@/views/brokers/BrokersList.vue'),
      meta: {
        pageTitle: 'Брокеры',
        resource: 'all',
        action: 'manage',
      },
      breadcrumb: [
        {
          text: 'Брокеры',
          to: { name: 'brokers-list' },
          active: true,
        },
      ],
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        resource: 'Auth',
        action: 'read',

        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Router Before Each hook for route protection
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'error-404' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'home' })
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
