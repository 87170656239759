export default {
  state: {
    user: {},
  },
  getters: {
    fullname: (state) => {
      return state.user.full_name
    },
    role: (state) => {
      return state.user.user_type
    },
  },
  mutations: {
    SET_USER(state, data) {
      state.user = data
    },
  },
  actions: {
    setUser({ commit }, data) {
      commit('SET_USER', data)
    },
  },
}
